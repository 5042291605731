import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    // paddingTop: theme.spacing.unit * 4
  },
  button: {
    ...theme.typography.paragraph,
    fontWeight: "bold",
    backgroundColor: "#4794ff",
    margin: "auto",
    padding: "15px",
    color: "white",
    textTransform: "uppercase",
    borderLeft: "4px solid #1e58a8",
    minWidth: "203px",
    boxSizing: "content-box"
  }
});

export default withStyles(styles)(
  class BlueButton extends React.Component {
    handleOnClick = to => {
      window.location.href = to;
    };

    render() {
      const { classes, text, to, onClick } = this.props;
      return (
        <div className={classes.container}>
          <button
            className={classes.button}
            onClick={onClick ? onClick : () => this.handleOnClick(to)}
          >
            {text}
          </button>
        </div>
      );
    }
  }
);
